<template>
    <div class="main-layout">
        <div class="language-selector">
            <div class="flag" @click="setLanguage( 'be_fr' )" v-if="$i18n.locale !== 'nl_nl'">
                <img src="/static/img/fr.svg" />
            </div>
            <div class="flag" @click="setLanguage( 'be_nl' )" v-if="$i18n.locale !== 'nl_nl'">
                <img src="/static/img/be.svg" />
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        methods: {
            setLanguage( pLang )
            {
                /*
                    can be: nl_nl, be_nl, be_fr
                */

                this.$i18n.locale = pLang;
            }
        }
    };
</script>
