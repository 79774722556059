import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => this.$t( 'message.form.errors.required' )// `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => this.$t( 'message.form.errors.min', { min: obj.min }),
                matches: obj => `Dit is geen geldig ${obj.label}.`,
                email: obj => this.$t( 'message.form.errors.email' )
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                fullname: {
                    label: this.$t( 'message.form.veld1.label' ),
                    name: 'fullname',
                    autocomplete: 'given-name',
                    tabindex: 1
                },

                emailaddress: {
                    label: this.$t( 'message.form.veld2.label' ),
                    name: 'emailaddress',
                    type: 'email',
                    tabindex: 2
                },

                agree: {
                    label: this.$t( 'message.form.veld3.label' ),
                    type: 'checkbox',
                    name: 'agree',
                    id: 'agree-checkbox',
                    tabindex: 3
                },
                question1: {
                    label: this.$t( 'message.form.question1.label' ),
                    name: 'question1',
                    tabindex: 4
                },
                question2: {
                    label: this.$t( 'message.form.question2.label' ),
                    name: 'question1',
                    tabindex: 4
                }
            },

            validationSchema: yup.object().shape({
                fullname: yup.string()
                    .label( this.$t( 'message.form.veld1.label' ) )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-\s])+$/ )
                    .min( 2 ),
                emailaddress: yup.string()
                    .label( this.$t( 'message.form.veld2.label' ) )
                    .required()
                    .email(),
                agree: yup.boolean()
                    .label( this.$t( 'message.form.veld3.label' ) )
                    .oneOf( [ true ], this.$t( 'message.form.errors.av' ) )
            })
        };
    }
};
